<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app>
      <v-sheet color="grey lighten-4" class="pa-4">
        <div>{{ me.email }}</div>
      </v-sheet>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
          v-for="[icon, text, link] in links"
          :key="icon"
          link
          :to="link"
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="white">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>MULTICARSERVICES <small>Manager</small></v-toolbar-title>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "templateAdmin",
  data() {
    return {
      drawer: true,
      me: undefined,
      links: [
        ["mdi-car-cruise-control", "Reprogramation", "/admin/reprogrammation"],
        ["mdi-car-coolant-level", "Liste des moteurs", "/admin/moteur"],
        ["mdi-book-open-blank-variant", "Contact", "/admin/contact"],
        ["mdi-alert-octagon", "Information", "/admin/info"],
        ["mdi-car-estate", "Camping Car", "/admin/campingCar"],
      ],
    };
  },
  created() {
    if (!this.$cookies.isKey("token")) {
      this.$router.push({ name: "login" });
    } else {
      this.me = this.$cookies.get("me");
      this.$router.push({ name: "ReprogramationView" });
    }
  },
  mounted() {},
};
</script>
