<template>
  <v-row class="py-5">
    <v-col cols="12" lg="6" sm="6"
      ><v-container class="px-8">
        <h1 class="text-center mb-3">Contactez nous !</h1>
        <p class="text-center">
          Vous pouvez nous contacter prendre rendez-vous et/ou avoir plus
          d'informations.
        </p>
        <div class="button-block">
          <div class="social">
            <a href="mailto:multicarservices31@outlook.fr"
              ><v-icon>mdi-email-outline</v-icon></a
            >
          </div>
          <p class="ma-0">
            <strong>Email</strong> :
            <a href="mailto:multicarservices31@outlook.fr"
              >multicarservices31@outlook.fr</a
            >
          </p>
        </div>
        <div class="button-block">
          <div class="social">
            <a href="tel:+33"><v-icon>mdi-phone-outline</v-icon></a>
          </div>
          <p class="ma-0">
            <strong>Tel</strong> : <a href="tel:+33764690207">07 64 69 02 07</a>
          </p>
        </div>
      </v-container>
    </v-col>
    <v-col cols="12" lg="6" sm="6">
      <v-img
        cover
        src="https://images.unsplash.com/photo-1512428559087-560fa5ceab42?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
      ></v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      id: undefined,
      content: undefined,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      try {
        const res = await this.$http.get(
          `${this.$core.api}/informations`,
          this.$core.apicfg
        );
        console.log(res);
        this.content = res.data.data[0].pageContact;
        this.id = res.data.data[0]._id;
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
    },
  },
};
</script>

<style scoped>
body {
  background: #147896;
}

a {
  color: black !important;
  text-decoration: none;
}

.button-block {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social {
  display: inline-block;
  margin: 30px;
  cursor: pointer;
}
.social a {
  display: block;
  color: black !important;
  background: black;
  padding: 10px 20px;
  position: relative;
  transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(0, 0);
  box-shadow: -20px 20px 10px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
}
.social a::before {
  dislpay: block;
  content: "";
  position: absolute;
  width: 20px;
  height: 100%;
  left: -20px;
  top: 10px;
  background: rgba(0, 0, 0, 0.7);
  transform: rotate(0deg) skewY(-45deg);
}
.social a::after {
  dislpay: block;
  content: "";
  position: absolute;
  bottom: -20px;
  left: -10px;
  height: 20px;
  width: 100%;
  transform: rotate(0deg) skewX(-45deg);
  background: rgba(0, 0, 0, 0.7);
  transform: rotate(45deg) scewY(-45deg);
}
.social a:hover {
  transform: perspective(1000px) rotate(-30deg) skew(25deg)
    translate(20px, -20px);
  box-shadow: -50px 50px 30px rgba(0, 0, 0, 0.5);
}
.social a i {
  color: white;
  font-size: 30pt;
}
</style>
