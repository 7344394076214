<template>
  <div class="pa-2" style="min-height: 70vh">
    <v-row class="flex-warp justify-center">
      <v-col cols="12" sm="9" md="5" lg="3" v-if="!model || !isMobile">
        <v-card>
          <v-card-title>
            <div class="col-12 pa-0">Choissez votre marque de voiture</div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchCar"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headersMarque"
            :items="voiture"
            :search="searchCar"
            :loading="loading"
            loading-text="Chargement..."
            :footer-props="{
              'items-per-page-text': '',
              pageText: '{0}-{1} de {2}',
              'items-per-page-options': [10000],
            }"
          >
            <template v-slot:item="{ item }">
              <tr class="col-12" @click="goModel(item.name, item.model)">
                <td style="min-width: 100%">{{ item.name }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="9"
        md="5"
        lg="3"
        v-show="isMobile === true ? model && !type : model"
      >
        <v-card>
          <v-card-title>
            <div class="col-12 pa-0">
              <div
                v-if="isMobile"
                @click="model = false"
                class="btn col-12"
                style="
                  padding: 10px;
                  transform: scale(-1, 1);
                  text-align: center;
                "
              >
                &#x279C;
              </div>
              {{ modelName }}
            </div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchMarque"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headersModel"
            :items="modelTable"
            :search="searchMarque"
            :footer-props="{
              'items-per-page-text': '',
              pageText: '{0}-{1} de {2}',
            }"
          >
            <template v-slot:item="{ item }">
              <tr @click="goType(item.name, item.type)">
                <td>{{ item.name }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="9"
        md="5"
        lg="3"
        v-if="isMobile === true ? type && !motor : type"
      >
        <v-card>
          <v-card-title>
            <div class="col-12 pa-0">
              <div
                v-if="isMobile"
                @click="type = false"
                class="btn col-12"
                style="
                  padding: 10px;
                  transform: scale(-1, 1);
                  text-align: center;
                "
              >
                &#x279C;
              </div>
              {{ typeName }}
            </div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchMarque"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headersType"
            :items="typeTable"
            :search="searchMarque"
            :footer-props="{
              'items-per-page-text': '',
              pageText: '{0}-{1} de {2}',
              'items-per-page-options': [10000],
            }"
          >
            <template v-slot:item="{ item }">
              <tr @click="goMotor(item.name, item.motor)">
                <td>{{ item.name }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" sm="9" md="5" lg="3" v-if="motor">
        <v-card>
          <v-card-title>
            <div class="col-12 pa-0">
              <div
                v-if="isMobile"
                @click="motor = false"
                class="btn col-12"
                style="
                  padding: 10px;
                  transform: scale(-1, 1);
                  text-align: center;
                "
              >
                &#x279C;
              </div>
              {{ motorName }}
            </div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchPuissance"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headersMotor"
            :items="motorTable"
            :search="searchPuissance"
            :footer-props="{
              'items-per-page-text': '',
              pageText: '{0}-{1} de {2}',
              'items-per-page-options': [10000],
            }"
          >
            <template v-slot:item="{ item }">
              <tr v-show="!item.disable" @click="goInfo(item)">
                <td>
                  {{ item.motor }} - {{ item.puissance }}cv

                  <v-tooltip
                    bottom
                    color="teal"
                    v-if="!item.soon && item.reprogrammation"
                    to="/contact"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        small
                        class="ma-2"
                        color="teal"
                        text-color="white"
                      >
                        Reprogrammation
                      </v-chip>
                    </template>
                    <span
                      >La reprogrammation sur cette voiture est possible.</span
                    >
                  </v-tooltip>
                  <v-tooltip bottom color="warning" v-if="item.soon">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        small
                        class="ma-2"
                        color="warning"
                        text-color="white"
                      >
                        Bientot (Reprogrammation)
                      </v-chip>
                    </template>
                    <span
                      >La reprogrammation sur cette voiture est bientot
                      disponnible.<br />
                      Merci de me contacter pour plus d'informations.</span
                    >
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    color="error"
                    v-if="!item.reprogrammation && !item.soon"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        small
                        class="ma-2"
                        color="error"
                        text-color="white"
                      >
                        Reprogrammation
                      </v-chip>
                    </template>
                    <span
                      >La reprogrammation sur cette voiture n'est pas
                      possible.</span
                    >
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    color="teal"
                    v-if="!item.soonE85 && item.e85"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        small
                        class="ma-2"
                        color="teal"
                        text-color="white"
                      >
                        E85
                      </v-chip>
                    </template>
                    <span
                      >Le passage a l'ethanol sur cette voiture est
                      possible.</span
                    >
                  </v-tooltip>
                  <v-tooltip bottom color="warning" v-if="item.soonE85">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        small
                        class="ma-2"
                        color="warning"
                        text-color="white"
                      >
                        Bientot (E85)
                      </v-chip>
                    </template>
                    <span
                      >Le passage a l'ethanol sur cette voiture est bientot
                      disponnible.<br />
                      Merci de me contacter pour plus d'informations.</span
                    >
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    color="error"
                    v-if="!item.e85 && !item.soonE85"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        small
                        class="ma-2"
                        color="error"
                        text-color="white"
                      >
                        E85
                      </v-chip>
                    </template>
                    <span
                      >Le passage a l'ethanol sur cette voiture n'est pas
                      possible.</span
                    >
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-dialog
        v-model="dialog"
        :width="`${info.reprogrammation && !info.soon ? '1300' : '600'}`"
      >
        <v-card style="overflow: hidden">
          <v-card-title class="text-h5 white lighten-2 mb-3">
            REPROGRAMMATION MOTEUR VOITURE
            <strong
              >{{ modelName }} {{ typeName }} {{ motorName }}
              {{ info.motor }}</strong
            >
            <v-tooltip
              bottom
              color="teal"
              v-if="!info.soon && info.reprogrammation"
              to="/contact"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="ma-2"
                  color="teal"
                  text-color="white"
                >
                  Reprogrammation
                </v-chip>
              </template>
              <span>La reprogrammation sur cette voiture est possible.</span>
            </v-tooltip>
            <v-tooltip bottom color="warning" v-if="info.soon">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="ma-2"
                  color="warning"
                  text-color="white"
                  to="/contact"
                >
                  Bientot (Reprogrammation)
                </v-chip>
              </template>
              <span
                >La reprogrammation sur cette voiture est bientot
                disponnible.<br />
                Merci de me contacter pour plus d'informations.</span
              >
            </v-tooltip>
            <v-tooltip
              bottom
              color="error"
              v-if="!info.reprogrammation && !info.soon"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="ma-2"
                  color="error"
                  text-color="white"
                >
                  Reprogrammation
                </v-chip>
              </template>
              <span
                >La reprogrammation sur cette voiture n'est pas possible.</span
              >
            </v-tooltip>
            <v-tooltip bottom color="teal" v-if="!info.soonE85 && info.e85">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="ma-2"
                  color="teal"
                  text-color="white"
                >
                  E85
                </v-chip>
              </template>
              <span
                >Le passage a l'ethanol sur cette voiture est possible.</span
              >
            </v-tooltip>
            <v-tooltip bottom color="warning" v-if="info.soonE85">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="ma-2"
                  color="warning"
                  text-color="white"
                  to="/contact"
                >
                  Bientot (E85)
                </v-chip>
              </template>
              <span
                >Le passage a l'ethanol sur cette voiture est bientot
                disponnible.<br />
                Merci de me contacter en cliquant pour plus
                d'informations.</span
              >
            </v-tooltip>
            <v-tooltip bottom color="error" v-if="!info.e85 && !info.soonE85">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="ma-2"
                  color="error"
                  text-color="white"
                >
                  E85
                </v-chip>
              </template>
              <span
                >Le passage a l'ethanol sur cette voiture n'est pas
                possible.</span
              >
            </v-tooltip>
          </v-card-title>
          <hr
            role="separator"
            aria-orientation="horizontal"
            class="v-divider theme--light"
          />

          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                lg="6"
                class="mt-5"
                v-if="info.reprogrammation && !info.soon"
              >
                <v-simple-table class="">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th></th>
                        <th class="text-left">Origine</th>
                        <th class="text-left">Gain</th>
                        <th class="text-left">Modifier</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Puissance</td>
                        <td>{{ info.puissance }} ch</td>
                        <td class="green--text">
                          + {{ info.winPuissance }} ch
                        </td>
                        <td>{{ info.puissance + info.winPuissance }} ch</td>
                      </tr>
                      <tr>
                        <td>Couple</td>
                        <td>{{ info.engine }} Nm</td>
                        <td class="green--text">+ {{ info.winEngine }} Nm</td>
                        <td>{{ info.engine + info.winEngine }} Nm</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-flex xs12 align-end flexbox style="height: 100%">
                  <p class="black--text">
                    * Chaque véhicule est mesuré et réglé sur banc, la puissance
                    indiquée est donc seulement à titre indicatif et peut tout à
                    fait varier suivant les conditions de mesure, l'état et
                    l'entretien général de votre véhicule.
                  </p>
                </v-flex>
              </v-col>
              <v-col
                cols="12"
                :lg="`${info.reprogrammation && !info.soon ? '6' : '12'}`"
                class="black--text mt-5"
              >
                <h3 class="text-center mb-2">INFORMATIONS</h3>
                <div style="display: block">
                  <p>- Mesure et log de votre véhicule sur banc de puissance</p>
                  <p>
                    - Optimisation sur mesure de votre gestion moteur Garantie
                    logiciel à VIE
                  </p>
                  <p>
                    - Transparence de l'intervention CT et Constructeur* Normes
                    anti-pollutions respectées
                  </p>
                  <p>- Diagnostic et Essai Route</p>
                  <p>- Véhicule de prêt possible (celon les disponibilité)</p>
                </div>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th></th>
                        <th class="text-left">Prix</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Reprogrammation</td>
                        <td
                          v-if="
                            !info.soon &&
                            info.reprogrammation &&
                            info.price !== null &&
                            info.price >= 0
                          "
                        >
                          {{ info.price }} €
                        </td>
                        <td v-if="info.soon && !info.reprogrammation">
                          Bientot
                        </td>
                        <td
                          v-if="
                            (!info.soon &&
                              !info.reprogrammation &&
                              info.price < 0) ||
                            info.price === null
                          "
                        >
                          Contactez nous
                        </td>
                      </tr>
                      <tr>
                        <td>E85</td>
                        <td
                          v-if="
                            !info.soonE85 &&
                            info.e85 &&
                            info.priceE85 !== null &&
                            info.priceE85 >= 0
                          "
                        >
                          {{ info.priceE85 }} €
                        </td>
                        <td v-if="info.soonE85 && !info.e85">Bientot</td>
                        <td
                          v-if="
                            (!info.soonE85 && !info.e85) ||
                            info.priceE85 === null ||
                            info.priceE85 <= 0 ||
                            !info.priceE85
                          "
                        >
                          Contactez nous
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <!-- <v-btn color="info" v-if="info.e85"
              >Besoin d'un véhicule de prêt ?</v-btn
            > -->
            <v-spacer></v-spacer>
            <v-btn color="success" to="/contact"> Contactez nous </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      dialog: false,
      modelName: "",
      searchCar: "",
      searchMarque: "",
      searchPuissance: "",
      searchAnnee: "",
      model: false,
      type: false,
      annee: false,
      motor: false,
      motorName: undefined,
      motorTable: [],
      modelTable: [],
      typeTable: [],
      anneeTable: [],
      info: {},
      headersMarque: [
        {
          text: "Marque de voiture",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
      headersModel: [
        {
          text: "Model",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
      headersType: [
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
      headersMotor: [
        {
          text: "Moteur",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
      desserts: [{ name: "est" }],
      voiture: [],
    };
  },
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        const res = await this.$http.get(
          `${this.$core.api}/reprogrammations?$limit=10000`,
          this.$core.apicfg
        );
        console.log(res);
        this.voiture = res.data.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    goModel(name, obj) {
      if (this.type === true) {
        this.type = false;
        this.typeTable = [];
        this.annee = false;
        this.anneeTable = [];
        this.motor = false;
        this.modelTable = [];
      }
      this.modelTable = obj;
      this.modelName = name;
      this.model = true;
    },
    goType(name, obj) {
      this.typeTable = obj;
      this.typeName = name;
      this.type = true;
      this.motor = false;
      this.motorTable = [];
    },
    goMotor(name, obj) {
      this.motorTable = obj;
      this.motorName = name;
      this.motor = true;
    },
    goInfo(obj) {
      if (obj.reprogrammation || obj.e85) {
        if (!obj.soon || !obj.soonE85) {
          this.info = obj;
          console.log(obj.info);
          this.dialog = true;
        }
      }
    },
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
