import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import e85 from "../views/E85View.vue";
import reprogrammation from "../views/ReprogrammationView.vue";
import contact from "../views/contactView.vue";
import test from "../views/test.vue";
import login from "../views/admin/LoginView.vue";
import Template from "../views/Template.vue";
import Menu from "../views/Menu.vue";
import Voiture from "../views/Voiture.vue";
import Bateau from "../views/Bateau.vue";
import Camion from "../views/Camion.vue";
import Moto from "../views/Moto.vue";
import CampingCar from "../views/CampingCar.vue";
// import Tracteur from "../views/Tracteur.vue";
import templateAdmin from "../views/admin/Template.vue";
import ReprogramationView from "../views/admin/ReprogramationView.vue";
import ContactViewAdmin from "../views/admin/ContactView.vue";
import motorView from "../views/admin/motorView.vue";
import infoView from "../views/admin/infoView.vue";
import CampingCarAdmin from "../views/admin/CampingCar.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "template",
    component: Template,
    children: [
      {
        path: "/",
        name: "home",
        component: HomeView,
      },
      {
        path: "/e85",
        name: "e85",
        component: e85,
      },
      {
        path: "/reprogrammation",
        name: "reprogrammation",
        component: reprogrammation,
      },
      {
        path: "/menu",
        name: "Menu",
        component: Menu,
      },
      {
        path: "/voitures",
        name: "Voiture",
        component: Voiture,
      },
      {
        path: "/motos",
        name: "Motos",
        component: Moto,
      },
      {
        path: "/camions",
        name: "Camions",
        component: Camion,
      },
      {
        path: "/bateaux",
        name: "Bateaux Jetski",
        component: Bateau,
      },
      // {
      //   path: "/tracteurs",
      //   name: "Tracteur",
      //   component: Tracteur,
      // },
      {
        path: "/contact",
        name: "contact",
        component: contact,
      },
      {
        path: "/campingCar",
        name: "CampinCar",
        component: CampingCar,
      },
      {
        path: "/test",
        name: "TestView",
        component: test,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/admin",
    name: "templateAdmin",
    component: templateAdmin,
    children: [
      {
        path: "/admin/reprogrammation",
        name: "ReprogramationView",
        component: ReprogramationView,
      },
      {
        path: "/admin/contact",
        name: "ContactViewAdmin",
        component: ContactViewAdmin,
      },
      {
        path: "/admin/moteur",
        name: "motorView",
        component: motorView,
      },
      {
        path: "/admin/info",
        name: "infoView",
        component: infoView,
      },
      {
        path: "/admin/campingCar",
        name: "CampinCarAdmin",
        component: CampingCarAdmin,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
