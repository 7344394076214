<template>
  <div class="container">
    <div class="card">
      <div class="box car" @click="$router.push('/voitures')">
        <div class="content">
          <h2></h2>
          <h3>Voitures</h3>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="box moto" @click="$router.push('/motos')">
        <div class="content">
          <h2></h2>
          <h3>Motos</h3>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="box bateau" @click="$router.push('/bateaux')">
        <div class="content">
          <h2></h2>
          <h3>Bateaux / Jetski</h3>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="box camion" @click="$router.push('/camions')">
        <div class="content">
          <h2></h2>
          <h3>Camions</h3>
        </div>
      </div>
    </div>

    <!-- <div class="card">
      <div class="box tracteur" @click="$router.push('/tracteurs')">
        <div class="content">
          <h2></h2>
          <h3>Tracteurs</h3>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "menuView",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100vh;
  background: #232427;
}

body .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

body .container .card {
  position: relative;
  min-width: 320px;
  height: 440px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin: 30px;
  transition: 0.5s;
}

.car {
  background: url("@/assets/voiture.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.moto {
  background: url("@/assets/moto.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bateau {
  background: url("@/assets/bateau.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.camion {
  background: url("@/assets/camion.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
/* .tracteur {
  background: url("@/assets/tracteur.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
} */

body .container .card .box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
  cursor: pointer;
}

body .container .card .box:hover {
  transform: translateY(-50px);
}

body .container .card .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

body .container .card .box .content {
  padding: 20px;
  text-align: center;
}

body .container .card .box .content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

body .container .card .box .content h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

body .container .card .box .content p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

body .container .card .box .content a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
</style>
