<template>
  <div>
    <div
      id="PowerStagePanel"
      style="width: 100%; background: rgba(255, 255, 255, 1); color: #000"
      VehicleTypeId="c30e9c1c-a7fa-4f5f-9303-25a3ad11549a"
    ></div>
  </div>
</template>
<script>
export default {
  name: "testView",
};
</script>

<style>
#PowerStagePanel td {
  border: 0px !important;
}

#PowerStagePanel .powerstage-panel-header img {
  height: 50px !important;
}
.powerstage-table-tr-hover {
  background: #bc1f34 !important;
}
.ps-navigation-bar-item {
  color: #bc1f34 !important;
}
</style>
