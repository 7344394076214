<template>
  <v-container white class="my-5 pa-10">
    <v-row>
      <v-col>
        <h1 class="text-center py-5">
          Le Bioéthanol E85 : Un Carburant Vert Pour un Futur Durable
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h2>Qu'est-ce que le Bioéthanol E85 ?</h2>
        <v-col>
          <v-img
            height="400"
            src="https://images.unsplash.com/photo-1602252595766-c69660da5b04?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
            alt="Champ de canne à sucre ou de maïs"
          ></v-img>
        </v-col>
        <p>
          Le <strong>bioéthanol E85</strong> est un carburant alternatif composé
          de 85 % de bioéthanol et de 15 % d'essence. Le
          <strong>bioéthanol</strong> est un alcool produit à partir de la
          fermentation de matières végétales riches en sucre ou en amidon,
          telles que la canne à sucre, le maïs, la betterave à sucre ou les
          céréales.
        </p>
      </v-col>
    </v-row>

    <h2 class="my-3">Comment est-il produit ?</h2>
    <p>
      La production de <strong>bioéthanol</strong> commence par la récolte de
      matières premières, comme le maïs ou la canne à sucre. Ces matières sont
      ensuite broyées pour en extraire le sucre ou l'amidon. Ensuite, ces
      éléments sont fermentés par des micro-organismes, comme les levures, pour
      produire de l'éthanol et du dioxyde de carbone. L'éthanol est ensuite
      distillé et mélangé à de l'essence pour former le carburant
      <strong>E85</strong>.
    </p>

    <h2 class="py-3">Avantages du Bioéthanol E85</h2>
    <v-row>
      <v-col style="min-width: 375px !important">
        <v-card class="card-info" height="235" elevation="0">
          <v-card-title primary-title>
            <h3>Énergie renouvelable</h3>
          </v-card-title>
          <v-card-text>
            <p>
              Le <strong>bioéthanol</strong> est produit à partir de matières
              premières qui peuvent être cultivées et renouvelées chaque année,
              contrairement aux combustibles fossiles, dont les réserves sont
              limitées.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col style="min-width: 375px !important">
        <v-card class="card-info" height="235" elevation="0">
          <v-card-title primary-title>
            <h3>Indépendance énergétique</h3>
          </v-card-title>
          <v-card-text>
            <p>
              La production de <strong>bioéthanol</strong> peut être réalisée
              localement, ce qui réduit la dépendance aux importations de
              pétrole et favorise l'autonomie énergétique des pays.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col style="min-width: 375px !important">
        <v-card class="card-info" height="235" elevation="0">
          <v-card-title primary-title>
            <h3>Coût réduit</h3>
          </v-card-title>
          <v-card-text>
            <p>
              Le <strong>bioéthanol E85</strong> est souvent moins cher à la
              pompe que l'essence traditionnelle. Cela peut s'expliquer par des
              politiques gouvernementales encourageant la production et la
              consommation de carburants renouvelables, ainsi que par la
              diversification des sources d'énergie qui contribue à réduire les
              coûts globaux.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col style="min-width: 375px !important">
        <v-card class="card-info" height="235" elevation="0">
          <v-card-title primary-title>
            <h3>Réduction des émissions de gaz à effet de serre</h3>
          </v-card-title>
          <v-card-text>
            <p>
              Le <strong>bioéthanol E85</strong> contribue à la réduction des
              émissions de CO2 grâce à la photosynthèse des plantes utilisées
              pour sa production, qui absorbent le CO2 de l'atmosphère. Ainsi,
              il crée un cycle presque neutre en termes d'émissions de CO2.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col style="min-width: 375px !important">
        <v-card class="card-info" height="235" elevation="0">
          <v-card-title primary-title>
            <h3>Performance du moteur</h3>
          </v-card-title>
          <v-card-text>
            <p>
              Les véhicules équipés de moteurs compatibles avec l'<strong
                >E85</strong
              >
              peuvent bénéficier d'une meilleure performance en raison de la
              résistance à la détonation plus élevée du
              <strong>bioéthanol</strong>. Cette caractéristique permet
              d'optimiser l'avance à l'allumage et d'obtenir une combustion plus
              efficace, ce qui se traduit par une augmentation de la puissance
              et du couple.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <h2 class="my-3">Inconvénients du Bioéthanol E85</h2>
    <v-row>
      <v-col style="min-width: 375px !important">
        <v-card class="card-info" height="160" elevation="0">
          <v-card-title primary-title>
            <h3>Utilisation des terres agricoles</h3>
          </v-card-title>
          <v-card-text>
            <p>
              La production de bioéthanol nécessite de grandes surfaces de
              terres cultivables, ce qui peut entraîner une concurrence avec la
              production alimentaire et provoquer une hausse des prix des
              denrées.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col style="min-width: 375px !important">
        <v-card class="card-info" height="160" elevation="0">
          <v-card-title primary-title>
            <h3>Consommation légèrement plus élevée</h3>
          </v-card-title>
          <v-card-text>
            <p>
              Les véhicules fonctionnant au
              <strong>bioéthanol E85</strong> consomment généralement plus de
              carburant que ceux utilisant de l'essence traditionnelle, en
              raison de la moindre densité énergétique du bioéthanol.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <h2 class="my-3">Conclusion</h2>
    <p>
      Le <strong>bioéthanol E85</strong> est une alternative prometteuse aux
      carburants fossiles, permettant de réduire les émissions de gaz à effet de
      serre et de soutenir l'autonomie énergétique des pays. Cependant, il est
      important de prendre en compte les défis liés à l'utilisation des terres
      agricoles et à la consommation de carburant. En pesant les avantages et
      les inconvénients, le <strong>bioéthanol E85</strong> peut jouer un rôle
      clé dans la transition vers un avenir plus durable.
    </p>
  </v-container>
</template>

<script>
export default {
  name: "testView",
};
</script>

<style scoped>
.card-info {
  background-color: transparent !important;
  border: 1px solid black !important;
  border-radius: 20px !important;
}
.card-info h3 {
  font-size: large;
}
.card-info p {
  color: black;
}
</style>
