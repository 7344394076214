<template>
  <div>
    <div class="white" style="height: 100vh; position: relative">
      <div class="blur"></div>
      <v-img
        style="filter: blur(0.5px)"
        height="100%"
        width="100%"
        src="../assets/car-paysage.jpg"
      ></v-img>
      <div class="logo col-sm-12">
        <!-- <v-img height="100%" max-width="100%" src="../assets/logo.png"></v-img> -->
        <h1 class="text-center mb-5" style="font-size: 5rem">
          <span>M</span>ULTI <span>C</span>AR <span>S</span>ERVICE
        </h1>
      </div>
      <div id="info" class="information enter">
        <h3>À DOMICILE</h3>
        <hr style="width: 140px; color: gainsboro; margin: auto" />
        <p>
          Le passage à l'éthanol (E85) et la reprogrammation<br />
          peuvent être faits directement chez vous !
        </p>
      </div>
      <div class="logo_text">
        <v-col cols="12" style="margin-bottom: 150px">
          <v-row class="align-center">
            <p class="">
              <strong>
                Passer à l'éthanol (E85) ?
                <span
                  style="text-decoration: underline; cursor: pointer"
                  @click="$router.push('/e85')"
                >
                  Cliquez ici
                </span>
                pour plus d'infos
              </strong>
            </p>
          </v-row>
        </v-col>
        <div class="wrapper">
          <div class="link_wrapper">
            <router-link to="/menu">Compatibilité de votre voiture</router-link>
            <div class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 268.832 268.832"
              >
                <path
                  d="M265.17 125.577l-80-80c-4.88-4.88-12.796-4.88-17.677 0-4.882 4.882-4.882 12.796 0 17.678l58.66 58.66H12.5c-6.903 0-12.5 5.598-12.5 12.5 0 6.903 5.597 12.5 12.5 12.5h213.654l-58.66 58.662c-4.88 4.882-4.88 12.796 0 17.678 2.44 2.44 5.64 3.66 8.84 3.66s6.398-1.22 8.84-3.66l79.997-80c4.883-4.882 4.883-12.796 0-17.678z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-container class="my-5">
      <v-row>
        <v-col cols="12" md="6">
          <v-card class="mx-auto" width="400" height="450" outlined>
            <v-img
              height="200"
              src="https://images.unsplash.com/photo-1527383418406-f85a3b146499?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"
              alt="Reprogrammation moteur"
            ></v-img>
            <v-card-title>Reprogrammation moteur</v-card-title>
            <v-card-text>
              Nos experts en reprogrammation moteur vous aideront à améliorer
              les performances de votre véhicule diesel ou essence. Profitez
              d'une augmentation de la puissance et du couple tout en réduisant
              votre consommation de carburant.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card class="mx-auto" width="400" height="450" outlined>
            <v-img
              height="200"
              src="https://images.unsplash.com/photo-1629241290025-6bb716261f5f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"
              alt="Conversion au bioéthanol"
            ></v-img>
            <v-card-title>Conversion au bioéthanol</v-card-title>
            <v-card-text>
              MultiCarServices est votre partenaire de choix pour la conversion
              au bioéthanol. Réduisez votre empreinte carbone et faites des
              économies sur le coût du carburant en optant pour une solution
              plus écologique et durable.
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="$router.push({ path: '/e85' })" color=""
                >En savoir plus</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-card class="mx-auto" width="400" height="450" outlined>
            <v-img
              height="200"
              src="https://images.unsplash.com/photo-1619642751034-765dfdf7c58e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2274&q=80"
              alt="Suppression de la vanne EGR et du filtre à particules"
            ></v-img>
            <v-card-title
              >Suppression de la vanne EGR et du <br />filtre à
              particules</v-card-title
            >
            <v-card-text>
              Notre équipe d'experts peut supprimer la vanne EGR et le filtre à
              particules de votre véhicule pour réduire les émissions polluantes
              et améliorer la performance globale de votre moteur.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card class="mx-auto" width="400" height="450" outlined>
            <v-img
              height="200"
              src="https://images.unsplash.com/photo-1626680114529-3f6ffa002b80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1285&q=80"
              alt="Réparation et aménagement de camping-cars"
            ></v-img>
            <v-card-title
              >Réparation et aménagement de <br />camping-cars</v-card-title
            >
            <v-card-text>
              MultiCarServices propose des services de réparation et
              d'aménagement intérieur pour les camping-cars. Faites confiance à
              nos professionnels pour créer un espace de vie confortable et
              fonctionnel pour vos voyages en camping-car.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card class="mx-auto" max-width="800" outlined>
            <v-card-title>Contactez-nous</v-card-title>
            <v-card-text>
              Vous avez des questions sur nos services ou vous souhaitez obtenir
              un devis personnalisé ? Contactez notre équipe dès aujourd'hui et
              nous serons ravis de vous aider.
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="$router.push({ path: '/contact' })" color=""
                >Nous contacter</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- <section>
      <v-layout column wrap class="my-5" align-center>
        <v-flex xs12 sm4 class="my-6">
          <div class="text-xs-center">
            <h2 class="headline">
              Informations sur la reprogrammation et l'E85 (l'éthanol)
            </h2>
          </div>
        </v-flex>
        <v-flex xs12 class="justify-space-between">
          <v-container grid-list-xl>
            <v-layout row wrap align-center justify-space-around>
              <v-col cols="12" lg="4">
                <v-card class="elevation-0 transparent" min-height="400">
                  <v-card-text class="text-xs-center pa-0">
                    <v-icon x-large class="red--text text--lighten-2 col-12"
                      >mdi-engine</v-icon
                    >
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-xs-center">Reprogrammation</div>
                  </v-card-title>
                  <v-card-text class="ml-10">
                    - Reprogrammation moteur sur mesure <br />
                    - Augmentation de la puissance <br />
                    - Augmentation du couple <br />
                    - Baisse de consommation de 5 à 10 % ( diesel ) <br />
                    - Diagnostic automobile <br />
                    - Garantie à Vie<br />
                    - Retour à l’origine GRATUIT<br />
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" lg="4">
                <v-card class="elevation-0 transparent" min-height="400">
                  <v-card-text class="text-xs-center pa-0">
                    <v-icon x-large class="red--text text--lighten-2 col-12"
                      >mdi-gas-station</v-icon
                    >
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-xs-center">E85</div>
                  </v-card-title>
                  <v-card-text>
                    La conversion à l’éthanol nécessite généralement une
                    demi-journée a une journée. Elle vous permettra de faire des
                    économies à chaque passage à la pompe, avec un prix moyen de
                    0.75 centime le litre d’E85, soit presque 50% du prix d’un
                    litre d’essence !!! <br />
                    En complément de l’économie produite, votre voiture sera
                    plus souple, plus réactive et d’une manière générale plus
                    agréable à conduire.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </section> -->
  </div>
</template>

<script>
export default {
  name: "ViewHome",
  components: {},
  data: () => ({
    show1: false,
    show2: false,
  }),
  methods: {
    link(link) {
      this.$router.push("/" + link);
    },
    onScroll() {
      const info = document.getElementById("info");
      if (window.top.scrollY >= 190) {
        info.classList.remove("enter");
        info.classList.add("exit");
      } else {
        info.classList.remove("exit");
        info.classList.add("enter");
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Arimo&display=swap");
.information {
  position: fixed;
  top: 85px;
  left: 10px;
  color: white;
  background: rgba(29, 29, 29, 0.6);
  padding: 5px 10px;
  border-radius: 20px;
  z-index: 6;
  text-align: center;
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
}
.enter {
  animation: enter 1s ease 0s 1 normal forwards;
}
.exit {
  animation: exit 1s ease 0s 1 normal forwards;
}

@keyframes exit {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    transform: translateX(+10px);
  }
  100% {
    opacity: 0;
    transform: translateX(-370px);
  }
}
@keyframes enter {
  0% {
    opacity: 0;
    transform: translateX(-370px);
  }
  80% {
    opacity: 1;
    transform: translateX(+10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.blur {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(79, 79, 79, 0.3);
  z-index: 2;
}
.logo {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.logo h1 {
  font-family: "Arimo", sans-serif;
  font-size: 20px;
}

.logo span {
  font-family: "Arimo", sans-serif;
  color: #bc1f34;
  font-size: 100px;
}
.logo_text {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 3;
}

body {
  font-family: "Lato", sans-serif;
}

.wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.link_wrapper {
  position: relative;
}

a {
  display: block;
  width: 350px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  text-decoration: none;
  background: #333;
  text-align: center;
  color: #fff !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 3px solid #333;
  transition: all 0.35s;
}

.icon {
  width: 50px;
  height: 50px;
  border: 3px solid transparent;
  position: absolute;
  transform: rotate(45deg);
  right: 0;
  top: 0;
  z-index: -1;
  transition: all 0.35s;
}

.icon svg {
  width: 30px;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  transform: rotate(-45deg);
  fill: #bc1f34;
  transition: all 0.35s;
}

a:hover {
  width: 375px;
  border: 3px solid #bc1f34;
  background: transparent;
  color: #bc1f34 !important;
}

a:hover + .icon {
  border: 3px solid #bc1f34;
  right: -18%;
}

/* Animation home text */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:40");

.blinking-cursor {
  user-select: none;
  animation: blink 1s steps(2, start) infinite;
}
@keyframes blink {
  to {
    visibility: hidden;
  }
}
@media (max-width: 768px) {
  .logo_text {
    width: 90%;
  }
  .logo span {
    font-size: 35px;
  }
  h1 {
    font-size: 2rem !important;
  }
}
</style>
