//Login.vue

<template>
  <section class="section-container">
    <v-row class="signin">
      <v-col cols="8" class="left">
        <h1>Admin interface</h1>
      </v-col>
      <v-col cols="4" class="right">
        <h2>CONNEXION</h2>
        <validation-observer ref="observer">
          <v-form @submit.prevent="submit">
            <validation-provider
              v-slot="{ errors }"
              name="Email"
              rules="required|email"
            >
              <v-text-field
                name="email"
                v-model="email"
                :error-messages="errors"
                label="Email"
                required
                outlined
                dark
                filled
                dense
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="Mot de passe"
              rules="required"
            >
              <v-text-field
                name="password"
                v-model="password"
                :error-messages="errors"
                label="Mot de passe"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPass = !showPass"
                required
                outlined
                dense
                dark
                filled
                :type="showPass ? 'text' : 'password'"
              ></v-text-field>
            </validation-provider>
            <div class="text-center">
              <v-btn
                class="signin-btn"
                type="submit"
                rounded
                color="white"
                dark
                @click="login()"
              >
                Se connecter
              </v-btn>
            </div>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { required, email } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  setInteractionMode,
  ValidationObserver,
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} ne peux pas être vide",
});

extend("email", {
  ...email,
  message: "Email doit être valide",
});

export default {
  data: () => ({
    email: "",
    password: null,
    showPass: false,
  }),
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    params() {
      return {
        email: this.email,
        password: this.password,
      };
    },
  },
  methods: {
    async login() {
      const data = {
        strategy: "local",
        email: this.email,
        password: this.password,
      };
      try {
        const authData = await this.$http.post(
          `${this.$core.api}/authentication`,
          data,
          this.$core.apicfg
        );
        console.log(authData);
        const user = authData.data.user;
        this.$cookies.set("me", user);
        this.$cookies.set("token", authData.data.accessToken);
        this.$core.apicfg.headers.Authorization = authData.data.accessToken;
        this.user = user.user;
        this.$router.push("/admin");
        // document.location.reload();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
/* ./assets/styles.scss */

.section-container {
  background: #fff;
  width: 100%;
  height: 100vh;
  box-shadow: 0 0 1px 1px rgba($color: #000000, $alpha: 0.1);
  box-sizing: border-box;
}
.signin {
  padding: 0;
  max-width: 100%;
  margin: 0 auto;
  height: 100vh;
  box-shadow: 0 0 1px 1px rgba($color: #000000, $alpha: 0.1);
}
.left {
  padding: 30px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  color: #30ac7c;
  background-color: #f9f9f9;
}
.right {
  padding: 30px;
  box-sizing: border-box;
  background: #30ac7c;
  color: #fff;
}
h2 {
  text-align: center;
  margin: 30px 0;
}
.signin-btn {
  width: 100%;
  color: #30ac7c;
}
</style>
