<template>
  <div>
    <div id="main"></div>
    <el-tiptap
      v-model="content"
      :extensions="extensions"
      :useBuiltInExtensions="extensions"
    />
    <v-col cols="12">
      <v-btn color="success" @click="save()">Enregistré</v-btn>
    </v-col>
  </div>
</template>

<script>
import {
  // necessary extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  TextAlign,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  TextColor,
  FontType,
  Link,
  Image,
  LineHeight,
  History,
  TextHighlight,
  Indent,
  HorizontalRule,
  TodoList,
  TodoItem,
  Preview,
} from "element-tiptap";

export default {
  data() {
    return {
      id: undefined,
      content: undefined,
      extensions: [
        new History(),
        new Doc(),
        new FontType(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }), // render command-button in bubble menu.
        new Underline({ bubble: true, menubar: false }), // render command-button in bubble menu but not in menubar.
        new Italic(),
        new Strike(),
        new TextColor({
          colors: ["#fff", "#000", "#c62827"],
        }),
        new Link(),
        new LineHeight(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new TextAlign(),
        new Table(),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new Image(),
        new TextHighlight(),
        new Indent(),
        new HorizontalRule(),
        new TodoList(),
        new TodoItem(),
        new Preview(),
      ],
      value: "# Hello World!",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        const res = await this.$http.get(
          `${this.$core.api}/informations`,
          this.$core.apicfg
        );
        console.log(res);
        this.content = res.data.data[0].campingCar;
        this.id = res.data.data[0]._id;
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
    },
    async save() {
      const data = {
        campingCar: this.content,
      };
      try {
        await this.$http.patch(
          `${this.$core.api}/informations/${this.id}`,
          data,
          this.$core.apicfg
        );
        this.$vToastify.success(`Enregistré !`);
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
    },
    onkeydown(event, view) {
      console.log("event", event.key);
      console.log(view);
    },
  },
};
</script>

<style scoped>
/* Menu bar float top sticky */
</style>
