<template>
  <div>
    <div
      id="PowerStagePanel"
      style="width: 100%; background: rgba(255, 255, 255, 1); color: #000"
      VehicleTypeId="e27fec35-cf46-4c11-a4b0-a36c3b58321e"
    ></div>
  </div>
</template>
<script>
export default {
  name: "MotoView",
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://ps.tuning-files.fr/panel/script"
    );
    document.head.appendChild(recaptchaScript);
  },
};
</script>

<style>
#PowerStagePanel td {
  border: 0px !important;
}

#PowerStagePanel .powerstage-panel-header img {
  height: 50px !important;
}
.powerstage-table-tr-hover {
  background: #bc1f34 !important;
}
.ps-navigation-bar-item {
  color: #bc1f34 !important;
}
</style>
