<template>
  <div class="px-12 py-4">
    <div class="el-dialog__body" style="background-color: white">
      <div class="el-tiptap-editor__content">
        <div v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "testView",
  data() {
    return {
      id: undefined,
      content: undefined,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      try {
        const res = await this.$http.get(
          `${this.$core.api}/informations`,
          this.$core.apicfg
        );
        console.log(res);
        this.content = res.data.data[0].campingCar;
        this.id = res.data.data[0]._id;
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
    },
  },
};
</script>

<style scoped></style>
