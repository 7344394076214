<template>
  <div>
    <v-data-iterator
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      :sort-by="sortBy.toLowerCase()"
      :sort-desc="sortDesc"
      hide-default-footer
    >
      <template v-slot:header>
        <v-toolbar dark color="red darken-3" class="mb-1">
          <v-text-field
            v-model="search"
            clearable
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Rechercher une marque"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="white" @click="dialogAdd = true" class="black--text"
            >Nouvelle Marque</v-btn
          >
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer></v-spacer>
            <v-btn-toggle v-model="sortDesc" mandatory>
              <v-btn large depressed color="white" :value="false">
                <v-icon class="black--text">mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn large depressed color="white" :value="true">
                <v-icon class="black--text">mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
      </template>

      <template v-slot:default="props">
        <!-- <v-text-field
          class="ma-5"
          v-model="searchElement"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
        ></v-text-field> -->
        <v-row class="mx-4">
          <v-col
            v-for="(item, i) in props.items"
            :key="i"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card>
              <v-card-title class="subheading font-weight-bold">
                {{ item.name }}
                <v-spacer></v-spacer>
                <v-icon style="cursor: pointer" @click="deleteItems(item._id)"
                  >mdi-close</v-icon
                >
              </v-card-title>

              <v-divider></v-divider>
              <!-- <v-data-table
                :items-per-page="5"
                :headers="carHeaders"
                :search="searchElement"
                :items="item.model"
                item-key="name"
                class="elevation-1"
              >
                <template v-slot:item.name="{ item }">
                  <span>{{ item }}</span>
                </template>
                <template v-slot:item.="items">
                  <tr
                    v-for="(model, index) in items.model"
                    :key="model._id"
                    style="cursor: pointer"
                  >
                    <td class="pa-1">
                      <div class="d-flex font-weight-bold align-content-center">
                        <v-col
                          cols="11"
                          @click="
                            openModalChange(item._id, index),
                              (idChange = item._id)
                          "
                          >{{ model.name }}</v-col
                        >
                        <v-spacer></v-spacer>
                        <v-icon
                          style="cursor: pointer"
                          @click="deleteSubItems(item._id, index)"
                          >mdi-close</v-icon
                        >
                      </div>
                    </td>
                  </tr>
                  <v-btn
                    style="width: 100%; border-radius: 0"
                    color="success"
                    @click="(dialogSubAdd = true), (idChange = item._id)"
                    >Ajouter</v-btn
                  >
                </template>
              </v-data-table> -->
              <table class="table table-hover">
                <thead></thead>
                <tbody>
                  <tr
                    class=""
                    v-for="(model, index) in item.model"
                    :key="model._id"
                    style="cursor: pointer"
                  >
                    <td class="pa-1">
                      <div class="d-flex font-weight-bold align-content-center">
                        <v-col
                          cols="11"
                          @click="
                            openModalChange(item._id, index),
                              (idChange = item._id)
                          "
                          >{{ model.name }}</v-col
                        >
                        <v-spacer></v-spacer>
                        <v-icon
                          style="cursor: pointer"
                          @click="deleteSubItems(item._id, index)"
                          >mdi-close</v-icon
                        >
                      </div>
                    </td>
                  </tr>
                  <v-btn
                    style="width: 100%; border-radius: 0"
                    color="success"
                    @click="(dialogSubAdd = true), (idChange = item._id)"
                    >Ajouter</v-btn
                  >
                </tbody>
              </table>
              <!-- <v-list dense>
                <v-list-item v-for="(key, index) in filteredKeys" :key="index">
                  <v-list-item-content
                    :class="{ 'blue--text': sortBy === key }"
                  >
                    {{ key }}:
                  </v-list-item-content>
                  <v-list-item-content
                    class="align-end"
                    :class="{ 'blue--text': sortBy === key }"
                  >
                    {{ item[key.toLowerCase()] }}
                  </v-list-item-content>
                </v-list-item>
              </v-list> -->
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row class="mt-2 mx-5" align="center" justify="center">
          <span class="grey--text">Element par page</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="error"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span class="mr-4 grey--text">
            Page {{ page }} sur {{ numberOfPages }}
          </span>
          <v-btn fab dark color="red darken-3" class="mr-1" @click="formerPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab dark color="red darken-3" class="ml-1" @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
    <!-- Modal Création marque -->
    <!-- -------------------------------------------------------------- -->
    <v-dialog v-model="dialogAdd" scrollable max-width="350px">
      <v-card>
        <v-card-title>Ajouter une nouvelle marque</v-card-title>
        <v-divider></v-divider>
        <v-card-text
          ><v-text-field
            v-model="nwMarqueField"
            label="Marque"
            placeholder="Marque"
          ></v-text-field
        ></v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialogAdd = false">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text @click="nwMarque()">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- -------------------------------------------------------------- -->
    <!-- Modal Création model -->
    <!-- -------------------------------------------------------------- -->
    <v-dialog v-model="dialogSubAdd" scrollable max-width="600px">
      <v-card>
        <v-card-title>Ajouter un nouveau model</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12"
              ><v-text-field
                v-model="nwItems.name"
                label="Model"
                placeholder="Model"
              ></v-text-field
            ></v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialogSubAdd = false">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text @click="nwSubMarque()">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- -------------------------------------------------------------- -->
    <!-- Modal Modification -->
    <!-- -------------------------------------------------------------- -->
    <v-dialog v-model="dialogEdit" scrollable max-width="600px">
      <v-card>
        <v-card-title>
          <v-text-field v-model="editItem.name" label="Model"></v-text-field
        ></v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-card
                class="pa-2 my-2"
                v-for="(item, i) in editItem.type"
                :key="i"
              >
                <v-row>
                  <v-col cols="8" sm="7">
                    <v-text-field
                      v-model="item.name"
                      label="Type + année"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-icon
                    class="mx-6"
                    style="cursor: pointer"
                    @click="deleteItemsType(item._id, i)"
                    >mdi-close</v-icon
                  >
                  <v-btn class="ma-3" @click="openModalChangeMotor(editItem, i)"
                    >Voir les motorisation</v-btn
                  >
                </v-row>
              </v-card>
              <v-btn class="btn success" @click="addType()">Ajouter</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialogEdit = false">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveType()">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- -------------------------------------------------------------- -->
    <v-dialog v-model="dialogMotorEdit" scrollable max-width="1100px">
      <v-card>
        <v-card-title>{{ motorEdit.name }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-card
                class="pa-5 my-2"
                style="border: 1px solid black"
                v-for="(item, i) in motorEdit.motor"
                :key="i"
              >
                <v-row>
                  <v-col
                    v-if="$vuetify.breakpoint.mdAndUp"
                    cols="8"
                    sm="3"
                    class="pa-0 pl-3"
                  >
                    <v-text-field
                      v-model="item.motor"
                      label="Motorisation"
                    ></v-text-field>
                  </v-col>
                  <v-row class="col-12" v-if="!$vuetify.breakpoint.mdAndUp">
                    <v-col cols="8" class="pa-0 pl-3">
                      <v-text-field
                        v-model="item.motor"
                        label="Motorisation"
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-icon
                      class="col-2"
                      style="cursor: pointer"
                      @click="deleteItemsMotor(item._id, i)"
                      >mdi-close</v-icon
                    >
                  </v-row>
                  <v-col cols="11" sm="3" class="pa-0 pl-3">
                    <v-text-field
                      class="pa-0"
                      v-model="item.puissance"
                      label="Puissance"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="11" sm="3" class="pa-0 pl-3">
                    <v-text-field
                      class="pa-0"
                      v-model="item.winPuissance"
                      label="Gain de puissance"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-icon
                    v-if="$vuetify.breakpoint.mdAndUp"
                    class="mx-6"
                    style="cursor: pointer"
                    @click="deleteItemsMotor(item._id, i)"
                    >mdi-close</v-icon
                  >
                  <v-col cols="11" sm="3" class="pa-0 pl-3">
                    <v-text-field
                      class="pa-0"
                      v-model="item.engine"
                      label="Couple"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="11" sm="3" class="pa-0 pl-3">
                    <v-text-field
                      class="pa-0"
                      v-model="item.winEngine"
                      label="Gain de couple"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="11" sm="2" class="pa-0 pl-3">
                    <v-text-field
                      class="pa-0"
                      v-model="item.price"
                      label="Prix"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pa-0 pl-3">
                    <v-row>
                      <v-col cols="12" lg="4" class="pa-0 pl-3">
                        <v-checkbox
                          v-model="item.e85"
                          label="E85"
                          @change="verif(item)"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" lg="4" class="pa-0 pl-3">
                        <v-checkbox
                          v-model="item.soonE85"
                          color="warning"
                          label="Bientot (E85)"
                          @change="verifSoon(item)"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="11" lg="2" v-if="item.e85" class="pa-0 pl-3">
                        <v-text-field
                          v-model="item.priceE85"
                          label="Prix E85"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" lg="4" class="pa-0 pl-3">
                    <v-checkbox
                      v-model="item.reprogrammation"
                      label="Reprogrammation"
                      @change="verif(item)"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" lg="4" class="pa-0 pl-3">
                    <v-checkbox
                      v-model="item.soon"
                      color="warning"
                      label="Bientot (Reprogrammation)"
                      @change="verifSoon(item)"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="12" class="pa-0 pl-3">
                    <v-radio-group v-model="item.type" row>
                      <v-radio label="Essence" value="essence"></v-radio>
                      <v-radio label="Gazoal" value="gazoal"></v-radio>
                      <v-radio label="Electrique" value="electrique"></v-radio>
                      <v-radio label="Hybrid" value="hybrid"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="6" lg="2" class="pa-0 pl-3">
                    <v-checkbox
                      v-model="item.disable"
                      color="error"
                      label="Désactiver"
                    ></v-checkbox>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="5">
                    <v-btn color="primary" @click="goToSaveList(i)"
                      >Ajouter dans la liste</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card>
              <v-btn class="btn success" @click="addMotor()">Ajouter</v-btn>
              <v-btn
                dark
                class="btn black ml-5"
                @click="openAddMotorWithTemplate()"
                >Ajouter a l'aide d'un template</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialogMotorEdit = false">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveMotor()">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- -------------------------------------------------------------- -->
    <v-dialog v-model="dialogSaveListMotor" scrollable max-width="350px">
      <v-card>
        <v-card-title>Ajouter un nouveau template de moteur</v-card-title>
        <v-divider></v-divider>
        <v-card-text
          ><v-text-field
            v-model="nwListMotor"
            label="Nom du template motor"
            placeholder="ex: AUDI A1 1.2 TFSI"
          ></v-text-field
        ></v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogSaveListMotor = false"
          >
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text @click="SaveListMotor()">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- -------------------------------------------------------------- -->
    <v-dialog v-model="dialogMotorWithTemplate" scrollable max-width="500px">
      <v-card>
        <v-card-title>Ajouter un moteur a l'aide d'un template</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-autocomplete
            v-model="motorTemplate"
            :items="motors"
            dense
            filled
            label="Chercher le template"
          >
            <template v-slot:selection="data">
              {{ data.item.title }}
            </template>
            <template v-slot:item="data">
              {{ data.item.title }}
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogMotorWithTemplate = false"
          >
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text @click="addMotorWithTemplate()">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- -------------------------------------------------------------- -->
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      dialogMotorWithTemplate: false,
      dialogSaveListMotor: false,
      dialogEdit: false,
      dialogSubAdd: false,
      e6: 1,
      dialogAdd: false,
      nwMarqueField: "",
      itemsPerPageArray: [4, 8, 10, 20, 50, 100, 200, 500],
      search: "",
      searchElement: "",
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 8,
      sortBy: "name",
      singleExpand: true,
      carHeaders: [
        {
          text: "Marque",
          align: "start",
          value: "name",
        },
      ],
      nwItems: {
        name: undefined,
        type: [],
      },
      editItem: {
        name: undefined,
        model: [
          {
            name: undefined,
            type: {
              name: undefined,
            },
          },
        ],
      },
      nwListMotor: undefined,
      motor: {},
      motors: {},
      motorTemplate: {},
      motorEdit: {},
      idChange: null,
      dialogMotorEdit: false,
      indexChange: undefined,
      indexTypeChange: undefined,
      items: [],
      car: [],
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.items = [];
      this.car = [];
      try {
        const res = await this.$http.get(
          `${this.$core.api}/reprogrammations?$limit=10000`,
          this.$core.apicfg
        );
        console.log(res);
        res.data.data.forEach((element) => {
          this.items.push(element);
          this.car.push(element.model);
        });
        console.log(this.items);
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
    },
    async openModalChange(id, i) {
      try {
        const res = await this.$http.get(
          `${this.$core.api}/reprogrammations/${id}`,
          this.$core.apicfg
        );
        console.log(res);
        this.editItem = res.data.model[i];
        this.indexChange = i;
        this.idChange = id;
        console.log(this.editItem);
        this.dialogEdit = true;
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
    },
    async openModalChangeMotor(data, i) {
      console.log(data);
      console.log(i);
      console.log(data.type[i].motor);
      this.motorEdit = data.type[i];
      console.log(this.motorEdit);
      this.indexTypeChange = i;
      this.dialogMotorEdit = true;
    },
    async nwMarque() {
      try {
        const data = {
          name: this.nwMarqueField,
          model: [],
        };
        const res = await this.$http.post(
          `${this.$core.api}/reprogrammations`,
          data,
          this.$core.apicfg
        );
        this.dialogAdd = false;
        console.log(res);
        this.init();
        this.idChange = res.data._id;
        this.$vToastify.success(`La marque : ${res.data.name} est bien créé`);
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
    },
    async nwSubMarque() {
      let data;
      try {
        const res = await this.$http.get(
          `${this.$core.api}/reprogrammations/${this.idChange}`,
          this.$core.apicfg
        );
        console.log(res);
        data = res.data;
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
      try {
        console.log(this.nwItems);
        data.model.push(this.nwItems);
        console.log(data);
        const res = await this.$http.patch(
          `${this.$core.api}/reprogrammations/${this.idChange}`,
          data,
          this.$core.apicfg
        );
        console.log(res);
        this.init();
        this.nwItems = {
          name: undefined,
          type: [],
        };
        this.dialogSubAdd = false;
        this.$vToastify.success(`Enregistré !`);
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
    },
    async saveType() {
      console.log(this.editItem);
      let data;
      try {
        const res = await this.$http.get(
          `${this.$core.api}/reprogrammations/${this.idChange}`,
          this.$core.apicfg
        );
        console.log(res);
        data = res.data;
        this.$vToastify.success(`Enregistré !`);
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
      try {
        data.model[this.indexChange] = this.editItem;
        const res = await this.$http.patch(
          `${this.$core.api}/reprogrammations/${this.idChange}`,
          data,
          this.$core.apicfg
        );
        console.log(res);
        this.init();
        this.editItem = {};
        this.dialogEdit = false;
        this.$vToastify.success(`Enregistré !`);
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
    },
    async saveMotor() {
      this.editItem.type[this.indexTypeChange].motor = this.motorEdit.motor;
      this.dialogMotorEdit = false;
    },
    async deleteItems(id) {
      this.$swal
        .fire({
          title: "Etes vous sur de vouloir supprimer",
          text: "La marque de la voiture sera totalement surpimer !",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Annuler",
          confirmButtonText: "Supprimer",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              const res = await this.$http.delete(
                `${this.$core.api}/reprogrammations/${id}`,
                this.$core.apicfg
              );
              console.log(res);
            } catch (error) {
              this.$vToastify.error(`${error.response.data.message}`);
            }
            this.$swal
              .fire(
                "Supprimé !",
                "La marque de la voiture est totalement supprimer",
                "success"
              )
              .then(() => {
                this.init();
              });
          }
        });
    },
    deleteItemsMotor(id, i) {
      this.$swal
        .fire({
          title: "Etes vous sur de vouloir supprimer",
          text: "La marque de la voiture sera totalement surpimer !",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Annuler",
          confirmButtonText: "Supprimer",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // ------------------------------------
            let data;
            try {
              const res = await this.$http.get(
                `${this.$core.api}/reprogrammations/${this.idChange}`,
                this.$core.apicfg
              );
              console.log(res);
              console.log(i);
              console.log(
                res.data.model[this.indexChange].type[this.indexTypeChange]
                  .motor[i]
              );
              data = res.data;
            } catch (error) {
              this.$vToastify.error(`${error.response.data.message}`);
            }
            try {
              data.model[this.indexChange].type[
                this.indexTypeChange
              ].motor.splice(i);
              const res = await this.$http.patch(
                `${this.$core.api}/reprogrammations/${this.idChange}`,
                data,
                this.$core.apicfg
              );
              console.log(res);
              this.motorEdit =
                res.data.model[this.indexChange].type[this.indexTypeChange];
              this.init();
            } catch (error) {
              this.$vToastify.error(`${error.response.data.message}`);
            }
            // -----------------------------------------
            this.$swal
              .fire(
                "Supprimé !",
                "La marque de la voiture est totalement supprimer",
                "success"
              )
              .then(() => {
                this.init();
              });
          }
        });
    },
    deleteItemsType(id, i) {
      this.$swal
        .fire({
          title: "Etes vous sur de vouloir supprimer",
          text: "La marque de la voiture sera totalement surpimer !",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Annuler",
          confirmButtonText: "Supprimer",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // ------------------------------------
            let data;
            try {
              const res = await this.$http.get(
                `${this.$core.api}/reprogrammations/${this.idChange}`,
                this.$core.apicfg
              );
              console.log(res);
              console.log(i);
              console.log(res.data.model[this.indexChange].type[i]);
              data = res.data;
            } catch (error) {
              this.$vToastify.error(`${error.response.data.message}`);
            }
            try {
              data.model[this.indexChange].type.splice(i);
              const res = await this.$http.patch(
                `${this.$core.api}/reprogrammations/${this.idChange}`,
                data,
                this.$core.apicfg
              );
              console.log(res);
              this.editItem = res.data.model[this.indexChange];
            } catch (error) {
              this.$vToastify.error(`${error.response.data.message}`);
            }
            // -----------------------------------------
            this.$swal
              .fire(
                "Supprimé !",
                "La marque de la voiture est totalement supprimer",
                "success"
              )
              .then(() => {
                this.init();
              });
          }
        });
    },
    async deleteSubItems(id, i) {
      this.$swal
        .fire({
          title: "Etes vous sur de vouloir supprimer",
          text: "La marque de la voiture sera totalement surpimer !",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Annuler",
          confirmButtonText: "Supprimer",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // ------------------------------------
            let data;
            try {
              const res = await this.$http.get(
                `${this.$core.api}/reprogrammations/${id}`,
                this.$core.apicfg
              );
              console.log(res);
              data = res.data;
            } catch (error) {
              this.$vToastify.error(`${error.response.data.message}`);
            }
            try {
              data.model.splice(i);
              const res = await this.$http.patch(
                `${this.$core.api}/reprogrammations/${id}`,
                data,
                this.$core.apicfg
              );
              console.log(res);
              this.init();
              this.dialogSubAdd = false;
            } catch (error) {
              this.$vToastify.error(`${error.response.data.message}`);
            }
            // -----------------------------------------
            this.$swal
              .fire(
                "Supprimé !",
                "La marque de la voiture est totalement supprimer",
                "success"
              )
              .then(() => {
                this.init();
              });
          }
        });
    },
    removeNull(array) {
      return array.filter((x) => x !== null);
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    async addType() {
      this.editItem.type.push({
        name: undefined,
        motor: [],
      });
      console.log(this.editItem);
      let data;
      try {
        const res = await this.$http.get(
          `${this.$core.api}/reprogrammations/${this.idChange}`,
          this.$core.apicfg
        );
        console.log(res);
        data = res.data;
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
      try {
        data.model[this.indexChange] = this.editItem;
        const res = await this.$http.patch(
          `${this.$core.api}/reprogrammations/${this.idChange}`,
          data,
          this.$core.apicfg
        );
        console.log(res);
        this.$vToastify.success(`Enregistré !`);
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
    },
    async addMotor() {
      console.log(this.motorEdit);
      this.motorEdit.motor.push({
        type: undefined,
        puissance: null,
        motor: undefined,
        price: null,
        e85: false,
        priceE85: null,
        soon: false,
        disable: false,
        winPuissance: null,
        winEngine: null,
        engine: null,
      });
      let data;
      try {
        const res = await this.$http.get(
          `${this.$core.api}/reprogrammations/${this.idChange}`,
          this.$core.apicfg
        );
        console.log(res);
        data = res.data;
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
      try {
        data.model[this.indexChange] = this.editItem;
        const res = await this.$http.patch(
          `${this.$core.api}/reprogrammations/${this.idChange}`,
          data,
          this.$core.apicfg
        );
        console.log(res);
        this.$vToastify.success(`Enregistré !`);
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
    },
    addItem() {
      this.addItems.push({
        nwSubPower: undefined,
        nwSubYear: undefined,
        nwSubPrice: undefined,
        nwSubCarburant: undefined,
      });
    },
    verif(item) {
      if (item.reprogrammation === true) {
        item.soon = false;
      }
      if (item.e85 === true) {
        item.soonE85 = false;
      }
    },
    verifSoon(item) {
      if (item.soon === true) {
        item.reprogrammation = false;
      }
      if (item.soonE85 === true) {
        item.e85 = false;
      }
    },
    goToSaveList(i) {
      this.motor = this.motorEdit.motor[i];
      console.log(this.motorEdit.motor[i]);
      this.dialogSaveListMotor = true;
    },
    async SaveListMotor() {
      try {
        const data = {
          title: this.nwListMotor,
          motor: this.motor,
        };
        console.log(data);
        const res = await this.$http.post(
          `${this.$core.api}/motors`,
          data,
          this.$core.apicfg
        );
        console.log(res);
        this.dialogSaveListMotor = false;
        this.$vToastify.success(`Enregistré !`);
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
    },
    async openAddMotorWithTemplate() {
      try {
        const res = await this.$http.get(
          `${this.$core.api}/motors?$limit=10000`,
          this.$core.apicfg
        );
        this.motors = res.data.data;
        console.log(this.motors);
        this.dialogMotorWithTemplate = true;
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
    },
    async addMotorWithTemplate() {
      console.log(this.motorTemplate);
      console.log(this.motorEdit);
      this.motorEdit.motor.push(this.motorTemplate.motor);
      let data;
      try {
        const res = await this.$http.get(
          `${this.$core.api}/reprogrammations/${this.idChange}`,
          this.$core.apicfg
        );
        console.log(res);
        data = res.data;
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
      try {
        data.model[this.indexChange] = this.editItem;
        const res = await this.$http.patch(
          `${this.$core.api}/reprogrammations/${this.idChange}`,
          data,
          this.$core.apicfg
        );
        console.log(res);
        this.$vToastify.success(`Enregistré !`);
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
      this.dialogMotorWithTemplate = false;
    },
  },
};
</script>

<style scoped>
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table-hover tbody tr:hover {
  background-color: rgba(219, 219, 219, 0.788);
}
</style>
