import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import vueCookies from "vue-cookies";
import axios from "axios";
import VueAxios from "vue-axios";
import VueSweetalert2 from "vue-sweetalert2";
import core from "./core/core";
import Vue2Editor from "vue2-editor";
import VueToastify from "vue-toastify";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
// don't forget to import CSS styles
import "tiptap-vuetify/dist/main.css";
// Vuetify's CSS styles
import "vuetify/dist/vuetify.min.css";

import "sweetalert2/dist/sweetalert2.min.css";

import ElementUI from "element-ui";
import { ElementTiptapPlugin } from "element-tiptap";
// import ElementUI's styles
import "element-ui/lib/theme-chalk/index.css";
// import this package's styles
import "element-tiptap/lib/index.css";

Vue.config.productionTip = false;
Vue.prototype.$core = core;
Vue.use(vueCookies, { expire: "1d" });
Vue.use(VueAxios, axios);
Vue.use(VueSweetalert2);
Vue.use(Vue2Editor);
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "mdi",
});
Vue.use(ElementUI);
// use this package's plugin
Vue.use(ElementTiptapPlugin, {
  lang: "fr", // see i18n
});
Vue.use(VueToastify, {
  theme: "light",
});
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
