<template>
  <div v-if="!isMobile">
    <v-app-bar app color="white" flat elevation="4">
      <v-img
        lazy-src="../assets/logo.png"
        max-height="64"
        max-width="185"
        src="../assets/logo.png"
      ></v-img>

      <v-tabs :value="linkName" centered class="ml-n9" color="grey darken-1">
        <v-tabs-slider color="#be1b2c"></v-tabs-slider>
        <v-tab
          active-class="active"
          v-for="link in links"
          :key="link.link"
          :to="link.link"
        >
          {{ link.name }}
        </v-tab>
      </v-tabs>
      <div class="">
        <div @click="$router.push({ path: '/contact' })" class="base">
          <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i>
          <div class="bg">Contactez nous</div>
        </div>
      </div>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <router-view @updateParent="linkNameUpdate" />
    </v-main>

    <v-footer light padless width="100%">
      <v-card
        flat
        tile
        class="white lighten-1 black--text text-center"
        width="100%"
      >
        <v-card-text>
          <v-btn
            v-for="icon in icons"
            :key="icon"
            class="mx-4 black--text"
            icon
            color="#be1b2c"
            :to="icon.link"
            target="_blank"
          >
            <v-icon size="24px">
              {{ icon.name }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text class="black--text pt-0">
          Vous pouvez nous contactez pour plus d'information et/ou prendre un
          rendez-vous.<br /><v-icon>mdi-email-outline</v-icon> :
          <a style="color: black" href="mailto:multicarservices31@outlook.fr"
            >multicarservices31@outlook.fr</a
          ><br /><v-icon>mdi-cellphone</v-icon>
          :
          <a style="color: black" href="tel:0764690207"> 07 64 69 02 07</a>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="black--text">
          {{ new Date().getFullYear() }} — <strong>MULTI CAR SERVICES</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
  <v-app id="inspire" v-else>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-img
        lazy-src="https://picsum.photos/id/11/10/6"
        max-height="64"
        max-width="185"
        src="../assets/logo.png"
      ></v-img>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-error--text text--accent-4"
        >
          <v-list-item v-for="link in links" :key="link.name" :to="link.link">
            <v-list-item-title>{{ link.name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main class="grey lighten-3">
      <router-view @updateParent="linkNameUpdate" />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    icons: [
      {
        link: "https://www.facebook.com/profile.php?id=100042494243229",
        name: "mdi-facebook",
      },
      // { link: "#", name: "mdi-linkedin" },
      {
        link: "https://www.instagram.com/multicarservices31/?hl=fr",
        name: "mdi-instagram",
      },
    ],
    links: [
      { link: "/", name: "Accueil" },
      { link: "/voitures", name: "Voitures" },
      { link: "/motos", name: "Motos" },
      { link: "/camions", name: "Camions" },
      { link: "/bateaux", name: "Bateaux Jetski" },
      // { link: "/tracteurs", name: "Tracteurs" },
      // { link: "/campingCar", name: "Camping Car" },
      { link: "/e85", name: "E85" },
      { link: "/contact", name: "Contact" },
    ],
    drawer: null,
  }),
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    linkNameUpdate(value) {
      this.linkName = value;
    },
  },
};
</script>

<style>
.v-tab--active {
  color: #bc1f34 !important;
}

.v-navigation-drawer {
  height: 100% !important;
  position: fixed !important;
}

/* Btb contact */

.base {
  cursor: pointer;
  position: relative;
  display: grid;
  grid-template-columns: repeat(30, 1fr);
  grid-template-rows: repeat(10, 1fr);
  border-radius: 999px;
  height: 30px;
  width: 200px;
  color: #fff;
  background: #000000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}

i {
  width: calc(200px / 30);
  height: calc(30px / 10);
}

i:nth-child(n + 1):nth-child(-n + 30):hover ~ .bg {
  --y: 10%;
}

i:nth-child(n + 31):nth-child(-n + 60):hover ~ .bg {
  --y: 20%;
}

i:nth-child(n + 61):nth-child(-n + 90):hover ~ .bg {
  --y: 30%;
}

i:nth-child(n + 91):nth-child(-n + 120):hover ~ .bg {
  --y: 40%;
}

i:nth-child(n + 121):nth-child(-n + 150):hover ~ .bg {
  --y: 50%;
}

i:nth-child(n + 151):nth-child(-n + 180):hover ~ .bg {
  --y: 60%;
}

i:nth-child(n + 181):nth-child(-n + 210):hover ~ .bg {
  --y: 70%;
}

i:nth-child(n + 211):nth-child(-n + 240):hover ~ .bg {
  --y: 80%;
}

i:nth-child(n + 241):nth-child(-n + 270):hover ~ .bg {
  --y: 90%;
}

i:nth-child(n + 271):nth-child(-n + 300):hover ~ .bg {
  --y: 100%;
}

i:nth-child(30n + 1):hover ~ .bg {
  --x: 3.3333333333%;
}

i:nth-child(30n + 2):hover ~ .bg {
  --x: 6.6666666667%;
}

i:nth-child(30n + 3):hover ~ .bg {
  --x: 10%;
}

i:nth-child(30n + 4):hover ~ .bg {
  --x: 13.3333333333%;
}

i:nth-child(30n + 5):hover ~ .bg {
  --x: 16.6666666667%;
}

i:nth-child(30n + 6):hover ~ .bg {
  --x: 20%;
}

i:nth-child(30n + 7):hover ~ .bg {
  --x: 23.3333333333%;
}

i:nth-child(30n + 8):hover ~ .bg {
  --x: 26.6666666667%;
}

i:nth-child(30n + 9):hover ~ .bg {
  --x: 30%;
}

i:nth-child(30n + 10):hover ~ .bg {
  --x: 33.3333333333%;
}

i:nth-child(30n + 11):hover ~ .bg {
  --x: 36.6666666667%;
}

i:nth-child(30n + 12):hover ~ .bg {
  --x: 40%;
}

i:nth-child(30n + 13):hover ~ .bg {
  --x: 43.3333333333%;
}

i:nth-child(30n + 14):hover ~ .bg {
  --x: 46.6666666667%;
}

i:nth-child(30n + 15):hover ~ .bg {
  --x: 50%;
}

i:nth-child(30n + 16):hover ~ .bg {
  --x: 53.3333333333%;
}

i:nth-child(30n + 17):hover ~ .bg {
  --x: 56.6666666667%;
}

i:nth-child(30n + 18):hover ~ .bg {
  --x: 60%;
}

i:nth-child(30n + 19):hover ~ .bg {
  --x: 63.3333333333%;
}

i:nth-child(30n + 20):hover ~ .bg {
  --x: 66.6666666667%;
}

i:nth-child(30n + 21):hover ~ .bg {
  --x: 70%;
}

i:nth-child(30n + 22):hover ~ .bg {
  --x: 73.3333333333%;
}

i:nth-child(30n + 23):hover ~ .bg {
  --x: 76.6666666667%;
}

i:nth-child(30n + 24):hover ~ .bg {
  --x: 80%;
}

i:nth-child(30n + 25):hover ~ .bg {
  --x: 83.3333333333%;
}

i:nth-child(30n + 26):hover ~ .bg {
  --x: 86.6666666667%;
}

i:nth-child(30n + 27):hover ~ .bg {
  --x: 90%;
}

i:nth-child(30n + 28):hover ~ .bg {
  --x: 93.3333333333%;
}

i:nth-child(30n + 29):hover ~ .bg {
  --x: 96.6666666667%;
}

i:nth-child(30n + 30):hover ~ .bg {
  --x: 100%;
}

.bg {
  border-radius: 999px;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  display: grid;
  place-content: center;
  width: 100%;
  height: 100%;
  grid-column: 1 / span 30;
  grid-row: 1 / span 10;
  transition: opacity 0.3s;
  pointer-events: none;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

i:hover ~ .bg {
  background: radial-gradient(
    circle 110px at var(--x) var(--y),
    rgb(255, 0, 0),
    rgba(169, 242, 136, 0)
  );
}
</style>
