<template>
  <div>
    <v-btn class="col-12 pa-0" dark color="black" @click="openMotorCreate()"
      >Ajouter</v-btn
    >
    <v-card>
      <v-card-title>
        Liste des moteurs
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="motors"
        :search="search"
        :footer-props="{
          'items-per-page-text': '',
          pageText: '{0}-{1} de {2}',
          'items-per-page-options': [10000],
        }"
      >
        <template v-slot:item="row">
          <tr>
            <td>{{ row.item.title }}</td>
            <td>{{ row.item.motor.puissance }}ch</td>
            <td>{{ row.item.motor.engine }}Nm</td>
            <td class="col-1">
              <v-btn
                class=""
                dark
                small
                color="black"
                @click="openMotorEdit(row.item)"
              >
                Ouvrir
              </v-btn>
            </td>
            <td class="col-1">
              <v-btn
                class=""
                dark
                small
                color="error"
                @click="deletteMotor(row.item._id)"
              >
                Supprimer
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-btn class="col-12 pa-0" dark color="black" @click="openMotorCreate()"
        >Ajouter</v-btn
      >
    </v-card>
    <v-dialog v-model="dialogMotorEdit" scrollable max-width="1100px">
      <v-card>
        <v-card-title>
          <v-col cols="12" class="pa-0 pl-3 pt-3">
            <v-text-field v-model="motor.title" label="Nom"></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-card class="pa-2 my-2">
                <v-row>
                  <v-col cols="12" sm="4" class="pa-0 pl-3 pt-3">
                    <v-text-field
                      v-model="motor.motor.motor"
                      label="Motorisation"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="pa-0 pl-3 pt-3">
                    <v-text-field
                      v-model="motor.motor.puissance"
                      label="Puissance"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" class="pa-0 pl-3 pt-3">
                    <v-text-field
                      v-model="motor.motor.winPuissance"
                      label="Gain de puissance"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="pa-0 pl-3">
                    <v-text-field
                      class="pa-0"
                      v-model="motor.motor.engine"
                      label="Couple"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="pa-0 pl-3">
                    <v-text-field
                      class="pa-0"
                      v-model="motor.motor.winEngine"
                      label="Gain de couple"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" class="pa-0 pl-3">
                    <v-text-field
                      class="pa-0"
                      v-model="motor.motor.price"
                      label="Prix"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pa-0 pl-3">
                    <v-row>
                      <v-col cols="12" lg="4" class="pa-0 pl-3">
                        <v-checkbox
                          v-model="motor.motor.e85"
                          label="E85"
                          @change="verif(motor.motor)"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" lg="4" class="pa-0 pl-3">
                        <v-checkbox
                          v-model="motor.motor.soonE85"
                          color="warning"
                          label="Bientot (E85)"
                          @change="verifSoon(motor.motor)"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        lg="2"
                        v-if="motor.motor.e85"
                        class="pa-0"
                      >
                        <v-text-field
                          v-model="motor.motor.priceE85"
                          label="Prix E85"
                          type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" lg="4" class="pa-0 pl-3">
                    <v-checkbox
                      v-model="motor.motor.reprogrammation"
                      label="Reprogrammation"
                      @change="verif(motor.motor)"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" lg="4" class="pa-0 pl-3">
                    <v-checkbox
                      v-model="motor.motor.soon"
                      color="warning"
                      label="Bientot (Reprogrammation)"
                      @change="verifSoon(motor.motor)"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" lg="5" class="pa-0 pl-3">
                    <v-radio-group v-model="motor.motor.type" row>
                      <v-radio label="Essence" value="essence"></v-radio>
                      <v-radio label="Gazoal" value="gazoal"></v-radio>
                      <v-radio label="Electrique" value="electrique"></v-radio>
                      <v-radio label="Hybrid" value="hybrid"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" lg="2" class="pa-0 pl-3">
                    <v-checkbox
                      v-model="motor.motor.disable"
                      color="error"
                      label="Désactiver"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialogMotorEdit = false">
            Annuler
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveMotor()">
            Enregistrer
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!create"
            class=""
            dark
            small
            color="error"
            @click="deletteMotor(motor._id)"
          >
            Supprimer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      create: false,
      dialogMotorEdit: undefined,
      search: "",
      headers: [
        {
          text: "Nom",
          align: "start",
          sortable: false,
          value: "title",
        },
        { text: "Puissance", value: "puissance" },
        { text: "Couple", value: "engine" },
        { text: "", value: "" },
      ],
      motors: [],
      motor: { title: undefined, motor: {} },
    };
  },
  methods: {
    async init() {
      this.motors = [];
      try {
        const res = await this.$http.get(
          `${this.$core.api}/motors?$limit=10000`,
          this.$core.apicfg
        );
        this.motors = res.data.data;
        console.log(this.motors);
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
    },
    async openMotorCreate() {
      this.create = true;
      this.motor = { title: undefined, motor: {} };
      this.dialogMotorEdit = true;
    },
    async openMotorEdit(motor) {
      this.create = false;
      this.motor = motor;
      console.log(this.motor);
      this.dialogMotorEdit = true;
    },
    async saveMotor() {
      const data = this.$core.clone(this.motor);
      console.log(data);
      try {
        console.log(data);
        console.log(this.$core.apicfg);
        if (this.create && data.title && data.motor) {
          const data1 = {
            title: this.motor.title,
            motor: this.motor.motor,
          };
          console.log(data1);
          const res = await this.$http.post(
            `${this.$core.api}/motors`,
            data1,
            this.$core.apicfg
          );
          console.log(res);
          this.create = false;
        } else if (data.title && data.motor) {
          await this.$http.patch(
            `${this.$core.api}/motors/${this.motor._id}`,
            data,
            this.$core.apicfg
          );
        }
        this.dialogMotorEdit = false;
        this.init();
        this.$vToastify.success(`Enregistré !`);
      } catch (error) {
        this.$vToastify.error(`${error.response.data.message}`);
      }
    },
    async deletteMotor(id) {
      this.$swal
        .fire({
          title: "Etes vous sur de vouloir supprimer",
          text: "Le moteur sera totalement supprimer !",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Annuler",
          confirmButtonText: "Supprimer",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              try {
                await this.$http.delete(
                  `${this.$core.api}/motors/${id}`,
                  this.$core.apicfg
                );
                this.dialogMotorEdit = false;
                this.init();
              } catch (error) {
                this.$vToastify.error(`${error.response.data.message}`);
              }
            } catch (error) {
              this.$vToastify.error(`${error.response.data.message}`);
            }
            // -----------------------------------------
            this.$swal
              .fire(
                "Supprimé !",
                "Le moteur est totalement supprimer",
                "success"
              )
              .then(() => {
                this.init();
              });
          }
        });
    },
    verif(item) {
      if (item.reprogrammation === true) {
        item.soon = false;
      }
      if (item.e85 === true) {
        item.soonE85 = false;
      }
    },
    verifSoon(item) {
      if (item.soon === true) {
        item.reprogrammation = false;
      }
      if (item.soonE85 === true) {
        item.e85 = false;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
