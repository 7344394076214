import moment from "moment";
import vueCookies from "vue-cookies";

// import store from "@/store/index"

export default {
  // api: "https://multicarservices.herokuapp.com" /* PROD */,
  api: "http://localhost:3030" /* TEST LOCAL */,
  apicfg: {
    emulated: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "bearer " + vueCookies.get("token"),
    },
  },
  emailValidate:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  rxTel:
    /([0-9]{2})[- ]*([0-9]{2})[- ]*([0-9]{2})[- ]*([0-9]{2})[- ]*([0-9]{2})/,
  age(date) {
    return date !== undefined && date !== null
      ? `${moment().diff(date, "years")} ans`
      : "NR";
  },
  date(date) {
    return date !== undefined && date !== null
      ? `${moment(date).locale("fr").format("DD-MM-yyyy")}`
      : "NR";
  },
  dateHtml(date) {
    return date !== undefined && date !== null
      ? `${moment(date).locale("fr").format("yyyy-MM-DD")}`
      : "NR";
  },
  formatDate(date) {
    return date !== undefined && date !== null
      ? `${moment(date).format()}`
      : "NR";
  },
  times(minutes) {
    const h = minutes / 60 || 0;
    const m = minutes % 60 || 0;
    return m === undefined || m === null
      ? "NR"
      : h < 1 || h === null
      ? `${moment().hours(h).minutes(m).locale("fr").format("mm[min]")}`
      : `${moment().hours(h).minutes(m).locale("fr").format("HH[h ]mm[min]")}`;
  },
  // writeFile(file) {
  //     return fs.writeFile(file)
  // },
  clone(object) {
    return JSON.parse(JSON.stringify(object));
  },
};
